import React from 'react';
import logo from 'images/logo-tanitech.png';

const Menu = () => {
  return (
    <section className="menu" id="ta-menu">
      <nav className="navbar navbar-expand beta-menu navbar-dropdown align-items-center navbar-fixed-top navbar-toggleable-sm">
        <button
          className="navbar-toggler navbar-toggler-right"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <div className="hamburger">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
        <div className="menu-logo">
          <div className="navbar-brand">
            <span className="navbar-caption-wrap">
              <a className="navbar-caption text-black ta-d-5" href="/">
                <img src={logo} className="mr-2" alt="Tanitech IT" /> Tanitech
                IT
              </a>
            </span>
          </div>
        </div>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul
            className="navbar-nav nav-dropdown nav-right"
            data-app-modern-menu="true"
          >
            <li className="nav-item">
              <a className="nav-link link text-black ta-d-4" href="index.html">
                Home
              </a>
            </li>
            <li className="nav-item d-none">
              <a className="nav-link link text-black ta-d-4" href="blog.html">
                Blog
              </a>
            </li>
            <li className="nav-item d-none">
              <a className="nav-link link text-black ta-d-4" href="post.html">
                Post
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link link text-black ta-d-4"
                href="#ta-about-us"
              >
                About Us
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link link text-black ta-d-4"
                href="#ta-contact-us"
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </section>
  );
};

export default Menu;
