import React from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

const ModalContent = styled.div`
  .modal-header {
    font-size: 16pt;
    font-weight: 600;
    color: #004b87;
    @media (min-width: 575px) {
      padding-right: 40px;
      padding-left: 40px;
    }
    background-color: white;
    padding-top: 20px;
    padding-bottom: 20px;
    button:focus {
      outline: none;
    }
  }
  .modal-body {
    font-size: 0.8rem;
    @media (min-width: 575px) {
      padding-right: 40px;
      padding-left: 40px;
    }
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: 'Open Sans', sans-serif;
    background-color: white;
    div, span, li {
      text-align: start;
    }
    h6 {
      margin-top: 2rem;
      margin-bottom: 1.2rem;
    }
    ul {
      margin-top: 0.6rem;
      margin-bottom: 1.2rem;
    }
    .heading_2 {
      font-size: 0.9rem;
    }
  }
  .modal-footer {
    font-size: 12pt;
    color: #004b87;
    background-color: white;
    justify-content: flex-start;
  }
`;

const ModalScreen = props => {
  return (
    <Modal size={props.size || 'lg'} show={props.show} onHide={props.onHide}>
      <ModalContent>
        <Modal.Header closeButton>{props.title}</Modal.Header>
        <Modal.Body>{props.content}</Modal.Body>
        {props.footer && <Modal.Footer>{props.footer}</Modal.Footer>}
      </ModalContent>
    </Modal>
  );
};

export default ModalScreen;
