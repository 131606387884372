import React, { Component } from 'react';
import Menu from 'components/menu';
import hero from 'images/workspace.jpg';
import hero_mobile from 'images/workspace-sm.jpg';
import wallpaper from 'images/wallpaper.jpg';
import Footer from 'components/footer';
import styled from 'styled-components';
import { device } from 'utils/constants';
import PrivacyPolicy from '../modal/privacyPolicy';
import logo_tinco from 'images/ic_tinco_playstore.png';
import logo_gimbaltrack from 'images/ic_gimbaltrack_playstore.png';

const Header = styled.section`
  background-image: ${'url(' + hero_mobile + ')'};
  @media ${device.mobileL} {
    background-image: ${'url(' + hero + ')'};
  }
`;

const SectionLight = styled.section`
  background-image: none;
`;

const SectionDark = styled.section`
  background-image: ${'url(' + wallpaper + ')'};
  color: white;
`;



class IndexPage extends Component {
  state = { showPrivacyPolicy: false };
  render() {
    return (
      <div>
        <Menu />

        <Header id="ta-header" className="ta-fullscreen ta-parallax-background">
          <div className="ta-overlay" />

          <div className="container align-center">
            <div className="row justify-content-md-center">
              <div className="ta-white col-md-10">
                <h1 className="ta-section-title ta-bold pb-3 ta-fonts-style ta-d-1">
                  <span className="title-text">Software Solutions</span>
                </h1>
                <p
                  className="ta-text pb-3 ta-fonts-style ta-d-5"
                  style={{ textShadow: '2px 2px #004B87' }}
                >
                  Our mission is to design innovative & efficient IT solutions{' '}
                </p>
                <div className="ta-section-btn mt-5 mt-sm-0">
                  <a
                    className="btn btn-md btn-secondary ta-d-4"
                    href="#ta-contact-us"
                  >
                    Get in Touch
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="ta-arrow hidden-sm-down" aria-hidden="true">
            <a href="#next">
              <i className="ta-i-down ta-iconfont"></i>
            </a>
          </div>
          <div id="next" />
        </Header>

        <SectionLight className="features1" id="ta-services">
          <div className="container">
            <div className="media-container-row">
              <div className="card p-3 col-12 col-md-6 col-lg-4">
                <div className="card-img pb-1 pb-sm-3">
                  <span className="ta-iconfont ta-i-responsive"></span>
                </div>
                <div className="card-box">
                  <h4 className="card-title py-3 ta-fonts-style ta-d-5">
                    <a href="/">Digitalisation</a>
                  </h4>
                  <p className="ta-text ta-fonts-style ta-d-7 px-2">
                    We aim to assist our partners in taking the adequate steps
                    towards digitalizing their businesses. Our primary goal is
                    to increase productivity and efficiency by providing
                    complete customized solutions tailored to different
                    entreprises needs.
                  </p>
                  <span className="vspacer"></span>
                  <p className="py-3 ta-fonts-style d-none">
                    <a href="/">READ MORE » </a>
                  </p>
                </div>
              </div>

              <div className="card p-3 col-12 col-md-6 col-lg-4">
                <div className="card-img pb-1 pb-sm-3">
                  <span className="ta-iconfont ta-i-idea"></span>
                </div>
                <div className="card-box">
                  <h4 className="card-title py-3 ta-fonts-style ta-d-5">
                    <a href="/">Innovation</a>
                  </h4>
                  <p className="ta-text ta-fonts-style ta-d-7 px-2">
                    Our expertise and our fixation on latest technology makes us
                    a vector for innovation. We are continuousely building
                    creative solutions for our partners in order to make their
                    businesses shine and get them one step closer to lead the
                    market.
                  </p>
                  <span className="vspacer"></span>
                  <p className="py-3 ta-fonts-style d-none">
                    <a href="/">READ MORE » </a>
                  </p>
                </div>
              </div>

              <div className="card p-3 col-12 col-md-6 col-lg-4">
                <div className="card-img pb-1 pb-sm-3">
                  <span className="ta-iconfont ta-i-code"></span>
                </div>
                <div className="card-box">
                  <h4 className="card-title py-3 ta-fonts-style ta-d-5">
                    <a href="/">Optimization</a>
                  </h4>
                  <p className="ta-text ta-fonts-style ta-d-7 px-2">
                    Since we believe that there is always room for improvement,
                    we accompany our partners in their journey to build a better
                    business. we put our diverse skillset at their disposal to
                    identify and implement enhancements to their existing
                    solutions.
                  </p>
                  <span className="vspacer"></span>
                  <p className="py-3 ta-fonts-style d-none">
                    <a href="/">READ MORE » </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </SectionLight>

        {/* <section className="ta-section content7 cid-ruNvXuN71m" id="content7-2">
          <div className="container">
            <div className="media-container-row">
              <div className="col-12 col-md-12">
                <div className="media-container-row">
                  <div className="media-content">
                    <h2 className="ta-section-title pb-3 align-center ta-fonts-style ta-d-2">
                      Hire us for your business needs!{" "}
                    </h2>
                    <div className="ta-section-text">
                      <p className="ta-text align-right mb-0 ta-fonts-style ta-d-7">
                        lorem aslalsa
                      </p>
                    </div>
                    <span className="vspacer"></span>
                    <div className="ta-section-btn">
                      <a className="btn btn-md btn-secondary ta-d-4" href="#">
                        Get a Quote
                      </a>
                    </div>
                  </div>
                  <div className="ta-figure" style={{ width: "75%" }}>
                    <img src={mbr} alt="" title=""></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <SectionDark id="ta-about-us">
          <div className="container">
            <div className="col-md-12">
              <div className="media-container-row">
                <div className=" align-left aside-content">
                  <h2 className="ta-title pt-2 ta-fonts-style ta-d-2">
                    About Us
                  </h2>
                  <div className="ta-section-text"></div>

                  <div className="block-content">
                    <div className="card p-3 pr-3">
                      <div className="media">
                        <div className="align-self-center card-img pb-3">
                          <span className="ta-iconfont ta-i-laptop"></span>
                        </div>
                        <div className="media-body">
                          <h4 className="card-title ta-fonts-style ta-d-7">
                            IT Consultancy & Software solutions provider
                          </h4>
                        </div>
                      </div>

                      <div className="card-box">
                        <p className="block-text ta-fonts-style ta-d-7 px-md-5">
                          Tanitech IT is a service provider that accompanies and
                          assist small and large businesses in developping their
                          own IT solutions and software applications. By opting
                          for smart and innovative solutions most businesses are
                          able to increase their efficiency and become more
                          competitive in their respective markets. Our main goal
                          is to fully understand our partners' needs in order to
                          help them leverage latest technology to become more
                          productive, increase their leadership and turn into a
                          more attractive and sustainable business.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SectionDark>

        <SectionLight id="ta-expertise">
          <div className="container">
            <h2 className="ta-section-title pb-3 align-center ta-fonts-style ta-d-2">
              Our Expertise
            </h2>

            {/* <h3 className="ta-section-subtitle ta-fonts-style ta-d-5">
              Over the years we have acquired a wide range of expertise and
              completed a diverse set of projects.
            </h3> */}

            <div className="row px-4 px-sm-0 mt-3 mt-sm-5">
              <div className="col-12 col-lg-6 text-elements">
                <h4 className="section-content-title pb-3 align-left ta-fonts-style ta-d-5">
                  Project Management
                </h4>
                <p className="section-content-text align-left ta-fonts-style ta-d-7">
                  Whether you are running a small business or huge Entreprise,
                  our solutions are well adapted to your organization needs. We
                  are specialized in creating web services and mobile
                  applications as part of complete solutions that are both
                  scalable and flexible. Scalability will enable you to grow as
                  a business whereas flexibility will allow you to switch
                  strategies and try out different business models. We operate
                  on 4 different axes:
                  <br />
                  <br />
                  <ul>
                    <li>
                      Plan adequately and elaborate a proper strategy
                    </li>
                    <li>
                      Design a solution that matches your business needs
                    </li>
                    <li>
                      Develop a product according to established technical specs
                    </li>
                    <li>
                      Deploy and manage rollouts into adequate infrastructure
                    </li>
                  </ul>
                </p>
              </div>
              <div className="progress_elements col-12 col-lg-6">
                <div className="progress1 pb-5">
                  <div className="title-wrap">
                    <div className="progressbar-title ta-fonts-style ta-d-7">
                      <p>Planning & Strategy</p>
                    </div>
                    <div className="progress_value ta-fonts-style ta-d-7 d-none">
                      <div className="progressbar-number"></div>
                      <span>%</span>
                    </div>
                  </div>
                  <progress
                    className="progress progress-primary "
                    max="100"
                    value="100"
                  ></progress>
                </div>

                <div className="progress1 pb-5">
                  <div className="title-wrap">
                    <div className="progressbar-title ta-fonts-style ta-d-7">
                      <p>Design & Conception</p>
                    </div>
                    <div className="progress_value ta-fonts-style ta-d-7 d-none">
                      <div className="progressbar-number"></div>
                      <span>%</span>
                    </div>
                  </div>
                  <progress
                    className="progress progress-primary "
                    max="100"
                    value="100"
                  ></progress>
                </div>

                <div className="progress2 pb-5">
                  <div className="title-wrap">
                    <div className="progressbar-title ta-fonts-style ta-d-7">
                      <p>Development & Implementation</p>
                    </div>
                    <div className="progress_value ta-fonts-style ta-d-7 d-none">
                      <div className="progressbar-number"></div>
                      <span>%</span>
                    </div>
                  </div>
                  <progress
                    className="progress progress-primary"
                    max="100"
                    value="100"
                  ></progress>
                </div>

                <div className="progress3 pb-5">
                  <div className="title-wrap">
                    <div className="progressbar-title ta-fonts-style ta-d-7">
                      <p>Deployment & Support</p>
                    </div>
                    <div className="progress_value ta-fonts-style ta-d-7 d-none">
                      <div className="progressbar-number"></div>
                      <span>%</span>
                    </div>
                  </div>
                  <progress
                    className="progress progress-primary"
                    max="100"
                    value="100"
                  ></progress>
                </div>
              </div>
            </div>
          </div>
        </SectionLight>

        <SectionLight className="ta-section" id="ta-products">
          <div className="container">
            <h2 className="ta-section-title pb-3 align-center ta-fonts-style ta-d-2">
              Our Products
            </h2>

            <div className="row px-4 px-sm-0 mt-3 mt-sm-5">
              <div className="col-12 col-lg-12 text-elements">
                {/* <h4 className="section-content-title pb-3 align-left ta-fonts-style ta-d-5">
                </h4> */}
                <p className="section-content-text align-left ta-fonts-style ta-d-7" style={{ marginBottom: 0 }}>
                  We are committed to deliver high quality softwares that meet our client needs, with particular focus on simplicity, accessibility and data protection of our users (<a class="nav-link link text-black ta-d-4" href="#ta-privacy" style={{ display: "contents" }}>Read below our privacy policy</a>). Some of the software products that we proudly launched:
                  <br />
                  <br />
                </p>
              </div>

              <div className="col-12 col-sm-2 text-elements mb-4 text-center">
                <img src={logo_tinco} alt="Tinco" width="80rem" />

              </div>
              <div className="col-12 col-sm-10 text-elements pt-1 mb-4">
                <strong>Tinco: </strong> An educational platform that provides a wide range of relevant courses that match users interests. It helps users to get started on their learning path to gain more knowledge in multiple topics & subjects. The service allows users to earn recognised certifications for their newly acquired skills and use them to promote their career.

              </div>

              <div id="logo-gimbaltrack" className="col-12 col-sm-2 text-elements mt-2 text-center">
                <img src={logo_gimbaltrack} alt="Gimbaltrack" width="80rem" />

              </div>
              <div className="col-12 col-sm-10 text-elements">
                <strong>GimbalTrack: </strong> A mobile application that provides a powerful and immersive gaming experience by turning users phone to a smart head-tracker. The application captures users head movements and forward that information to any game using OpenTrack or flight simulation software, without the need to rely on any extra device.

              </div>


              <div className="col-12 col-lg-12 pt-sm-5" id="ta-privacy" >
                <div className="row justify-content-center pt-3">
                  <div className="ta-section-btn mt-5 mt-sm-0">
                    <button
                      className="btn btn-md btn-secondary ta-d-4"
                      onClick={() => this.props.history.push('/privacy-policy')}
                    >
                      Privacy policy
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </SectionLight>

        <SectionDark className="ta-section" id="ta-contact-us">
          <div className="container">
            <div className="row main justify-content-center">
              <div className="media-container-column title col-12 col-md-5 my-3 align-center px-5 px-sm-0">
                <h2 className="ta-bold ta-white pb-3 ta-fonts-style">
                  <span style={{ fontWeight: 'normal' }}>
                    Let us help you digitalize and grow your business!
                  </span>
                </h2>
                <h3 className="ta-section-subtitle ta-light ta-white ta-fonts-style ta-d-4">
                  We can help you apply smart software solutions in your
                  business in order to increase your efficiency and provide
                  better service to your customers.
                </h3>
              </div>
              <div className="media-container-column col-12 col-md-6 my-3 mt-5 mt-sm-0">
                <div className="contact-area d-flex flex-column justify-content-center">
                  <div className="mb-3">
                    <span className="ta-iconfont ta-i-paper-plane mr-4">
                      {' '}
                      Email:
                    </span>
                    <a href="mailto:business@tanitech-it.com">
                      business@tanitech-it.com
                    </a>
                  </div>
                  <div className="mb-3">
                    <span className="ta-iconfont ta-i-mobile mr-4">
                      {' '}
                      Phone:
                    </span>
                    <a href="tel:(+46)070358363">(+46)0700358363</a>
                  </div>
                  <div className="mb-2">
                    <span className="ta-iconfont ta-i-map-pin mr-2">
                      {' '}
                      Address:
                    </span>
                    <a
                      href="http://maps.google.com/?q=Celsiusgatan 8, Stockholm"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      11230 - Stockholm
                    </a>
                  </div>
                </div>
                <div className="ta-section-btn align-left py-4 d-none">
                  <a className="btn btn-secondary ta-d-4" href="/">
                    Email Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </SectionDark>

        <Footer />

        {/* <PrivacyPolicy
          show={this.state.showPrivacyPolicy}
          onHide={() => this.setState({ showPrivacyPolicy: false })}
        /> */}
      </div>
    );
  }

  renderPrivacyPolicy = () => {
    const { showPrivacyPolicy } = this.state;
    if (showPrivacyPolicy) {
      return (
        <PrivacyPolicy
          show={showPrivacyPolicy}
          onHide={() => this.setState({ showPrivacyPolicy: false })}
        />
      );
    }
  };
}


export default IndexPage;
