import React from "react";

const Footer = () => {
  return (
    <section id="ta-footer">
      <div className="container">
        <div className="media-container-row">
          <div className="col-md-3">
            <div className="media-wrap">
              <a href="/">
                <h2>Tanitech IT</h2>
              </a>
            </div>
          </div>
          <div className="col-md-9">
            <p className="ta-text align-right links ta-fonts-style ta-d-7 d-none">
              <a href="/" className="text-black">
                ABOUT
              </a>{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;
              <a href="/" className="text-black">
                TERMS
              </a>{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;
              <a href="/" className="text-black">
                CAREERS
              </a>{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;
              <a href="/" className="text-black">
                CONTACT
              </a>
            </p>
          </div>
        </div>
        <div className="footer-lower">
          <div className="media-container-row">
            <div className="col-md-12">
              <hr />
            </div>
          </div>
          <div className="media-container-row ta-white">
            <div className="col-md-6 copyright">
              <p className="ta-text ta-fonts-style ta-d-7">
                © Copyright 2023 - All Rights Reserved
              </p>
            </div>
            <div className="col-md-6">
              <div className="social-list align-right">
                <div className="social-item">
                  <a href="/" target="_blank">
                    <span />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
