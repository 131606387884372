import React, { Component } from "react";
import IndexPage from "components/pages";
import "styles/App.scss";
import PrivacyPolicy from 'components/modal/privacyPolicy';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

class App extends Component {
  state = {};
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={IndexPage} />
          <Route path="/privacy-policy" children={<IndexPage />} />
          <Route path="*" render={() => <Redirect to="/" />} />
        </Switch>
        <Route path="/privacy-policy" component={PrivacyPolicy} />
      </Router>
    );
  }
}

export default App;
