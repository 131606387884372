import React, { Component } from 'react';

import propTypes from 'prop-types';
import ModalScreen from './modalScreen';


class PrivacyPolicy extends Component {

  render() {
    return (
      <React.Fragment>
        <ModalScreen
          show={true}
          title="Privacy Policy"
          footer={this.renderFooter()}
          onHide={() => this.props.history.push('/')}
          content={this.renderPolicy()}
          size="md"
        />
      </React.Fragment>
    );
  }

  renderPolicy = () => {
    return (
      <div className="text-center">
        <div className="col-12">
          <div className="row">
            <div><strong><span>Last updated <bdt class="question">December 2023</bdt></span></strong></div>
            <br /><br />
            <span><span data-custom-class="body_text">This privacy notice for <bdt class="question">Tanitech IT<bdt class="block-component"></bdt></bdt> (<bdt class="block-component"></bdt>"<strong>we</strong>," "<strong>us</strong>," or "<strong>our</strong>"<bdt class="statement-end-if-in-editor"></bdt></span><span data-custom-class="body_text">), describes how and why we might collect, store, use, and/or share (<bdt class="block-component"></bdt>"<strong>process</strong>"<bdt class="statement-end-if-in-editor"></bdt>) your information when you use our services (<bdt class="block-component"></bdt>"<strong>Services</strong>"<bdt class="statement-end-if-in-editor"></bdt>), such as when you:</span></span>
            <ul>
              <li>Download and use our mobile application (<bdt class="question">Tinco</bdt>) or any other application of ours that links to this privacy notice</li>
              <li>Engage with us in other related ways, including any sales, marketing, or events<bdt class="statement-end-if-in-editor"></bdt></li>
            </ul>
            <span data-custom-class="body_text"><strong>Questions or concerns?&nbsp;</strong>Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services.<bdt class="block-component"></bdt> If you still have any questions or concerns, please contact us at <bdt class="question"><a href="mailto:privacy@tanitech-it.com">privacy@tanitech-it.com</a> </bdt>.</span>

            <div><h6><strong><span>SUMMARY OF KEY POINTS</span></strong></h6>

              <div><span><span data-custom-class="body_text"><strong>What personal information do we process?</strong> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make and features you use. </span></span><span data-custom-class="body_text"> </span></div>
              <br />
              <div><span><span data-custom-class="body_text"><strong>Do we process any sensitive personal information?</strong> <bdt class="block-component"></bdt>We do not process sensitive personal information.<bdt class="else-block"></bdt></span></span></div>
              <br />
              <div><span><span data-custom-class="body_text"><strong>Do we receive any information from third parties?</strong> <bdt class="block-component"></bdt>We do not receive any information from third parties.<bdt class="else-block"></bdt></span></span></div>
              <br />
              <div><span><span data-custom-class="body_text"><strong>How do we process your information?</strong> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. </span></span><span data-custom-class="body_text"></span></div>
              <br />
              <div><span><span data-custom-class="body_text"><strong>In what situations and with which <bdt class="block-component"></bdt>parties do we share personal information?</strong> We may share information in specific situations and with specific <bdt class="block-component"></bdt>third parties.</span></span><span><span data-custom-class="body_text"><bdt class="block-component"></bdt></span></span></div>
              <br />
              <div><span><span data-custom-class="body_text"><strong>How do we keep your information safe?</strong> We have <bdt class="block-component"></bdt>organizational<bdt class="statement-end-if-in-editor"></bdt> and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other <bdt class="block-component"></bdt>unauthorized<bdt class="statement-end-if-in-editor"></bdt> third parties will not be able to defeat our security and improperly  access or modify your information. </span></span><span data-custom-class="body_text"></span><span><span data-custom-class="body_text"><bdt class="statement-end-if-in-editor"></bdt></span></span></div>
              <br />
              <div><span><span data-custom-class="body_text"><strong>What are your rights?</strong> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. </span></span><span data-custom-class="body_text">.</span></div>
              <br />
              <div><span><span data-custom-class="body_text"><strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.</span></span></div>
              <br />
              <div><span><span data-custom-class="body_text">Want to learn more about what we do with any information we collect?&nbsp;</span></span><a data-custom-class="link" href="#toc"><span><span data-custom-class="body_text">Review the privacy notice in full</span></span></a><span><span data-custom-class="body_text">.</span></span></div>

            </div>

            <div><h6><strong><span>1. WHAT INFORMATION DO WE COLLECT?</span></strong></h6>
              <div id="personalinfo"><span class="heading_2"><strong>Personal information you disclose to us</strong></span></div>
              <br />
              <div><span><span><span data-custom-class="body_text"><span><span><span data-custom-class="body_text"><strong><em>In Short:</em></strong></span></span></span></span><span data-custom-class="body_text"><span><span><span data-custom-class="body_text"><strong><em>&nbsp;</em></strong><em>We collect personal information that you provide to us.</em></span></span></span></span></span></span></div>
              <br />
              <div><span><span><span data-custom-class="body_text">We collect personal information that you voluntarily provide to us when you <span><bdt class="block-component"></bdt></span>register on the Services,&nbsp;</span><span><span data-custom-class="body_text"><span><bdt class="statement-end-if-in-editor"></bdt></span></span><span data-custom-class="body_text">express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</span></span></span></span></div>
              <br />
              <div><span><span><span data-custom-class="body_text"><strong>Personal Information Provided by You.</strong> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:<span><span data-custom-class="body_text"><bdt class="forloop-component"></bdt></span></span></span></span></span></div>
              <div>
                <ul>
                  <li><span><span><span data-custom-class="body_text"><span><span data-custom-class="body_text"><bdt class="question">email address</bdt></span></span></span></span></span></li>
                  <li><span><span><span data-custom-class="body_text"><span><span data-custom-class="body_text"><bdt class="question">name</bdt></span></span></span></span></span></li>
                  <li><span><span><span data-custom-class="body_text"><span><span data-custom-class="body_text"><bdt class="question">year of birth</bdt></span></span></span></span></span></li>
                  <li><span><span><span data-custom-class="body_text"><span><span data-custom-class="body_text"><bdt class="question">gender</bdt></span></span></span></span></span></li>
                  <li><span><span><span data-custom-class="body_text"><span><span data-custom-class="body_text"><bdt class="question">occupation</bdt></span></span></span></span></span></li>
                  <li><span><span><span data-custom-class="body_text"><span><span data-custom-class="body_text"><bdt class="question">country</bdt></span></span></span></span></span></li>
                  <li><span><span><span data-custom-class="body_text"><span><span data-custom-class="body_text"><bdt class="question">interests</bdt></span></span></span></span></span></li>
                </ul>
              </div>
              <div><span data-custom-class="body_text"><strong>Sensitive Information.</strong> <bdt class="block-component"></bdt>We do not process sensitive information.</span></div>
              <br />
              <div><span data-custom-class="body_text"><strong>Social Media Login Data.&nbsp;</strong>We may provide you with the option to register with us using your existing social media account details, like your Facebook, Twitter, or other social media account. If you choose to register in this way, we will collect the information described in the section called <bdt class="block-component"></bdt>"<bdt class="statement-end-if-in-editor"></bdt><span><span data-custom-class="body_text"><span><span>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span></span></span></span><bdt class="block-component"></bdt>"<bdt class="statement-end-if-in-editor"></bdt> below.</span></div>
              <br />
              <div><span data-custom-class="body_text">All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</span></div>
              <br />
              <div><span class="heading_2"><span><strong>Information automatically collected</strong></span></span></div>
              <br />
              <div><span><span data-custom-class="body_text"><span><span><span data-custom-class="body_text"><strong><em>In Short:</em></strong></span></span></span></span><span data-custom-class="body_text"><span><span><span data-custom-class="body_text"><strong><em>&nbsp;</em></strong><em>Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.</em></span></span></span></span></span></div>
              <br />
              <div><span data-custom-class="body_text">We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</span></div>
              <br />
              <div><span><span><span data-custom-class="body_text">The information we collect includes:<bdt class="block-component"></bdt></span></span></span></div>
              <div style={{ marginBottom: "-20px" }}><ul><li><span data-custom-class="body_text"><em>Log and Usage Data.</em> Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services<span>&nbsp;</span>(such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called <bdt class="block-component"></bdt>"crash dumps"<bdt class="statement-end-if-in-editor"></bdt>), and hardware settings).<span><span><span data-custom-class="body_text"><span><span><bdt class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span></li></ul></div>

            </div>


            <div id="infouse"><h6><strong><span>2. HOW DO WE PROCESS YOUR INFORMATION?</span></strong></h6>
              <div><span data-custom-class="body_text"><strong><em>In Short:&nbsp;</em></strong><em>We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</em></span></div>
              <br />
              <div><span><span><span data-custom-class="body_text">We process your personal information for a variety of reasons, depending on how you interact with our Services, including:<bdt class="block-component"></bdt></span></span></span></div>
              <div style={{ marginBottom: "-20px" }}>
                <ul>
                  <li><span><span><span data-custom-class="body_text"><strong>To facilitate account creation and authentication and otherwise manage user accounts.&nbsp;</strong>We may process your information so you can create and log in to your account, as well as keep your account in working order.<span><span><span data-custom-class="body_text"><span><span><span data-custom-class="body_text"><span><span><span data-custom-class="body_text"><bdt class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span></span></span></span></span></span></span></li>
                  <li><span data-custom-class="body_text"><span><strong>To save or protect an individual's vital interest.</strong> We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.</span></span><bdt class="statement-end-if-in-editor"><span><span data-custom-class="body_text"></span></span></bdt></li>
                  <li><span data-custom-class="body_text"><span><bdt class="question"><strong>To deliver targeted content</strong></bdt><strong>.</strong> <bdt class="question"></bdt></span></span></li>
                </ul>
              </div>
            </div>

            <div><h6><strong><span>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</span></strong></h6>
              <div><span data-custom-class="body_text"><strong>In Short:&nbsp;</strong>We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e.<bdt class="block-component"></bdt>,<bdt class="statement-end-if-in-editor"></bdt> legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or <bdt class="block-component"></bdt>fulfill<bdt class="statement-end-if-in-editor"></bdt> our contractual obligations, to protect your rights, or to <bdt class="block-component"></bdt>fulfill<bdt class="statement-end-if-in-editor"></bdt> our legitimate business interests.</span></div>
              <br />
              <div><span><span data-custom-class="body_text">The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:</span></span></div>
              <div style={{ marginBottom: "-20px" }}>
                <ul>
                  <li><span><span data-custom-class="body_text"><strong>Consent.&nbsp;</strong>We may process your information if you have given us permission (i.e.<bdt class="block-component"></bdt>,<bdt class="statement-end-if-in-editor"></bdt> consent) to use your personal information for a specific purpose. You can withdraw your consent at any time.</span></span><span data-custom-class="body_text"></span></li>
                  <li><span data-custom-class="body_text"><span><strong>Legitimate Interests.</strong> We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process your personal information for some of the purposes described in order to develop and display personalized and relevant content for users.</span></span></li>
                  <li><span data-custom-class="body_text"><span><strong>Legal Obligations.</strong> We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.<bdt class="statement-end-if-in-editor"></bdt></span></span></li>
                  <li><span data-custom-class="body_text"><span><strong>Vital Interests.</strong> We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.</span></span><bdt class="statement-end-if-in-editor"><span><span data-custom-class="body_text"></span></span></bdt></li>
                </ul>
              </div>
            </div>

            <div><h6><strong><span>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</span></strong></h6>
              <div><span><span data-custom-class="body_text"><strong><em>In Short:</em></strong><em>&nbsp;We may share information in specific situations described in this section and/or with the following <bdt class="block-component"></bdt>third parties.</em></span></span></div>
              <br />
              <div><span data-custom-class="body_text">We <bdt class="block-component"></bdt>may need to share your personal information in the following situations:</span></div>
              <div style={{ marginBottom: "-20px" }}>
                <ul>
                  <li><span><span data-custom-class="body_text"><strong>Business Transfers.</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</span></span></li>
                  <li><span data-custom-class="body_text"><span><strong>Offer Wall.&nbsp;</strong>Our application(s) may display a third-party hosted <bdt class="block-component"></bdt>"offer wall."<bdt class="statement-end-if-in-editor"></bdt> Such an offer wall allows third-party advertisers to offer virtual currency, gifts, or other items to users in return for the acceptance and completion of an advertisement offer. Such an offer wall may appear in our application(s) and be displayed to you based on certain data, such as your geographic area or demographic information. When you click on an offer wall, you will be brought to an external website belonging to other persons and will leave our application(s). A unique identifier, such as your user ID, will be shared with the offer wall provider in order to prevent fraud and properly credit your account with the relevant reward.<bdt class="statement-end-if-in-editor"></bdt></span></span><bdt class="statement-end-if-in-editor"><span><span data-custom-class="body_text"></span></span></bdt></li>
                </ul>
              </div>
            </div>

            <div><h6><strong><span>5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span></strong></h6>
              <div><span data-custom-class="body_text"><strong><em>In Short:&nbsp;</em></strong><em>If you choose to register or log in to our Services using a social media account, we may have access to certain information about you.</em></span></div>
              <br />
              <div><span><span><span data-custom-class="body_text">Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform.<span><span><span data-custom-class="body_text"><bdt class="block-component"></bdt></span></span></span></span></span></span></div>
              <br />
              <div><span><span><span data-custom-class="body_text">We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps.<span><span><span><span><span><span><span><span><span data-custom-class="body_text"><bdt class="statement-end-if-in-editor"></bdt></span><bdt class="block-component"><span data-custom-class="body_text"><bdt class="block-component"></bdt></span></bdt></span></span></span></span></span></span></span></span></span></span></span></div>
            </div>

            <div><h6><strong><span>6. HOW LONG DO WE KEEP YOUR INFORMATION?</span></strong></h6>
              <div><span><span><span data-custom-class="body_text"><strong><em>In Short:&nbsp;</em></strong><em>We keep your information for as long as necessary to <bdt class="block-component"></bdt>fulfill<bdt class="statement-end-if-in-editor"></bdt> the purposes outlined in this privacy notice unless otherwise required by law.</em></span></span></span></div>
              <br />
              <div><span><span><span data-custom-class="body_text">We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).<bdt class="block-component"></bdt> No purpose in this notice will require us keeping your personal information for longer than <span><span><span data-custom-class="body_text"><bdt class="block-component"></bdt></span></span>&nbsp;</span> <bdt class="block-component"></bdt>the period of time in which users have an account with us<bdt class="block-component"></bdt><span><span><span data-custom-class="body_text"><bdt class="else-block"></bdt></span></span></span>.</span></span></span></div>
              <br />
              <div><span><span><span data-custom-class="body_text">When we have no ongoing legitimate business need to process your personal information, we will either delete or <bdt class="block-component"></bdt>anonymize<bdt class="statement-end-if-in-editor"></bdt> such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.<span><bdt class="block-component"></bdt></span></span></span></span></div>
            </div>

            <div><h6><strong><span>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</span></strong></h6>
              <div><span><span><span data-custom-class="body_text"><strong><em>In Short:&nbsp;</em></strong><em>We aim to protect your personal information through a system of <bdt class="block-component"></bdt>organizational<bdt class="statement-end-if-in-editor"></bdt> and technical security measures.</em></span></span></span></div>
              <br />
              <div><span><span><span data-custom-class="body_text">We have implemented appropriate and reasonable technical and <bdt class="block-component"></bdt>organizational<bdt class="statement-end-if-in-editor"></bdt> security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other <bdt class="block-component"></bdt>unauthorized<bdt class="statement-end-if-in-editor"></bdt> third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.<span><bdt class="statement-end-if-in-editor"></bdt></span><span><span data-custom-class="body_text"><bdt class="block-component"></bdt></span></span></span></span></span></div>
            </div>

            <div><h6><strong><span>8. DO WE COLLECT INFORMATION FROM MINORS?</span></strong></h6>
              <div><span><span><span data-custom-class="body_text"><strong><em>In Short:</em></strong><em>&nbsp;We do not knowingly collect data from or market to <bdt class="block-component"></bdt>children under 18 years of age<bdt class="else-block"></bdt>.</em><bdt class="block-component"></bdt></span></span></span></div>
              <br />
              <div><span><span><span data-custom-class="body_text">We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at <span><span data-custom-class="body_text"><bdt class="block-component"></bdt><bdt class="question"><a href="mailto:privacy@tanitech-it.com">privacy@tanitech-it.com</a></bdt><bdt class="else-block"></bdt></span></span>.</span><span data-custom-class="body_text"><bdt class="else-block"><bdt class="block-component"></bdt></bdt></span></span></span></div>
            </div>

            <div><h6><strong><span>9. WHAT ARE YOUR PRIVACY RIGHTS?</span></strong></h6>
              <div><span><span><span data-custom-class="body_text"><strong><em>In Short:</em></strong><em>&nbsp;<span><span><span data-custom-class="body_text"><em><bdt class="block-component"></bdt></em></span></span></span>In some regions, such as <bdt class="block-component"></bdt>the European Economic Area (EEA), United Kingdom (UK), and Switzerland<bdt class="block-component"></bdt>, you have rights that allow you greater access to and control over your personal information.<span><span><span data-custom-class="body_text"><em><bdt class="statement-end-if-in-editor"></bdt></em></span></span>&nbsp;</span>You may review, change, or terminate your account at any time.</em><span><span><bdt class="block-component"></bdt></span></span></span></span></span></div>
              <br />
              <div><span><span><span data-custom-class="body_text">In some regions (like <bdt class="block-component"></bdt>the EEA, UK, and Switzerland<bdt class="block-component"></bdt>), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; (iv) if applicable, to data portability; and (v) not to be subject to automated decision-making. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section <bdt class="block-component"></bdt>"<bdt class="statement-end-if-in-editor"></bdt></span></span></span><span><span><span data-custom-class="body_text">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></span></span><span><span><span data-custom-class="body_text"><bdt class="block-component"></bdt>"<bdt class="statement-end-if-in-editor"></bdt> below.</span></span></span></div>
              <br />
              <div><span><span><span data-custom-class="body_text">We will consider and act upon any request in accordance with applicable data protection laws.<bdt class="block-component"></bdt></span></span></span></div>
              <br />
              <div><span><span><span data-custom-class="body_text">If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your <span><span><span data-custom-class="body_text"><span><span data-custom-class="body_text"><a data-custom-class="link" href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm" rel="noopener noreferrer" target="_blank"><span>Member State data protection authority</span></a></span></span></span></span></span> or&nbsp;</span></span></span><a data-custom-class="link" href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/" rel="noopener noreferrer" target="_blank"><span><span><span data-custom-class="body_text">UK data protection authority</span></span></span></a><span><span><span data-custom-class="body_text">.</span></span></span><span><span><span data-custom-class="body_text">If you are located in Switzerland, you may contact the <span><span><span data-custom-class="body_text"><span><span data-custom-class="body_text"><span><a data-custom-class="link" href="https://www.edoeb.admin.ch/edoeb/en/home.html" rel="noopener noreferrer" target="_blank">Federal Data Protection and Information Commissioner</a></span></span></span></span></span></span>.</span></span></span></div>
              <br />
              <div id="withdrawconsent"><span><span><span data-custom-class="body_text"><strong><u>Withdrawing your consent:</u></strong> If we are relying on your consent to process your personal information,<bdt class="block-component"></bdt><bdt class="else-block"></bdt> you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section <bdt class="block-component"></bdt>"<bdt class="statement-end-if-in-editor"></bdt></span></span></span><span><span><span data-custom-class="body_text">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></span></span><span><span><span data-custom-class="body_text"><bdt class="block-component"></bdt>"<bdt class="statement-end-if-in-editor"></bdt> below<bdt class="block-component"></bdt>.</span></span></span></div>
              <br />
              <div><span><span data-custom-class="body_text">However, please note that this will not affect the lawfulness of the processing before its withdrawal nor,<bdt class="block-component"></bdt><bdt class="else-block"></bdt> will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.<bdt class="block-component"></bdt></span></span><bdt class="block-component"><span><span data-custom-class="body_text"></span></span></bdt></div>
              <br />
              <div><span class="heading_2"><strong>Account Information</strong></span></div>
              <br />
              <div><span data-custom-class="body_text"><span>If you would at any time like to review or change the information in your account or terminate your account, you can contact us using the contact information provided.<bdt class="forloop-component"></bdt></span></span></div>
              <br />
              <div><span><span data-custom-class="body_text">Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</span></span><bdt class="statement-end-if-in-editor"><span><span data-custom-class="body_text"></span></span></bdt><span><span><span data-custom-class="body_text"><span><span><span><span><span data-custom-class="body_text"><span><span><bdt class="block-component"></bdt></span></span></span></span></span></span></span></span></span></span><bdt class="block-component"><span><span data-custom-class="body_text"></span></span></bdt></div>
              <br />
              <div><span data-custom-class="body_text"><span>If you have questions or comments about your privacy rights, you may email us at <a href="mailto:privacy@tanitech-it.com">privacy@tanitech-it.com</a>.</span></span><bdt class="statement-end-if-in-editor"><span><span data-custom-class="body_text"></span></span></bdt></div>

            </div>

            <div><h6><strong><span>10. CONTROLS FOR DO-NOT-TRACK FEATURES</span></strong></h6>
              <div><span><span><span data-custom-class="body_text">Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (<bdt class="block-component"></bdt>"DNT"<bdt class="statement-end-if-in-editor"></bdt>) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for <bdt class="block-component"></bdt>recognizing<bdt class="statement-end-if-in-editor"></bdt> and implementing DNT signals has been <bdt class="block-component"></bdt>finalized<bdt class="statement-end-if-in-editor"></bdt>. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.<bdt class="block-component"></bdt></span></span></span><bdt class="block-component"><span></span></bdt><bdt class="block-component"><span></span></bdt></div>
            </div>

            <div><h6><strong><span>11. DO WE MAKE UPDATES TO THIS NOTICE?</span></strong></h6>
              <div><span><span><span data-custom-class="body_text"><em><strong>In Short:&nbsp;</strong>Yes, we will update this notice as necessary to stay compliant with relevant laws.</em></span></span></span></div>
              <br />
              <div><span><span><span data-custom-class="body_text">We may update this privacy notice from time to time. The updated version will be indicated by an updated <bdt class="block-component"></bdt>"Revised"<bdt class="statement-end-if-in-editor"></bdt> date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</span></span></span></div>
            </div>

            {/* <div><h6><strong><span>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></strong></h6>
              <div><span><span><span data-custom-class="body_text">If you have questions or comments about this notice, you may <span><span data-custom-class="body_text"><bdt class="block-component"><bdt class="block-component"></bdt></bdt>email us at <a href="mailto:privacy@tanitech-it.com">privacy@tanitech-it.com</a><bdt class="statement-end-if-in-editor"><bdt class="block-component"></bdt></bdt></span></span><span><span><span data-custom-class="body_text"></span></span></span></span></span></span></div>
            </div> */}

            <div><h6><strong><span>12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span></strong></h6>
              <div><span><span><span data-custom-class="body_text"><bdt class="block-component"></bdt>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. <bdt class="else-block"><bdt class="block-component"></bdt>To request to review, update, or delete your personal information, please contact us at <a href="mailto:privacy@tanitech-it.com">privacy@tanitech-it.com</a> and we will send you a </bdt></span><span><span data-custom-class="body_text"><span>data subject access request to fill out and submit.</span></span></span><bdt class="block-component"><span data-custom-class="body_text"></span></bdt></span></span><span data-custom-class="body_text"></span></div>
            </div>

            <br />

          </div>
        </div >
      </div >
    );
  };

  renderFooter = () => {
    return (
      <div className="w-100">
        <span></span>
      </div>
    );
  };
}

PrivacyPolicy.propTypes = {
  show: propTypes.bool,
  onHide: propTypes.func
};

export default PrivacyPolicy;
